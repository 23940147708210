import React, { memo, useState } from "react";
import Banner1 from "./images/Banners/one.png";
import Banner2 from "./images/Banners/two.png";
import Banner3 from "./images/Banners/three.png";
import Desk1 from "./images/Banners/desk2.png";
import Desk2 from "./images/Banners/desk3.png";
import Desk3 from "./images/Banners/desk1.png";
import { motion } from "framer-motion";
import {
  Non_Veg_Soups120,
  Non_Veg_Soups150,
  Prawns_Starters1200,
  Prawns_Starters1500,
  Fish_Starters120,
  Fish_Starters150,
  Egg_Items120,
  Egg_Items150,
  Kababs_Grills120,
  Kababs_Grills150,
  Spl_Fish_Tikka120,
  Spl_Fish_Tikka150,
  Chicken_Starters120,
  Chicken_Starters150,
  Mutton_Starters120,
  Mutton_Starters150,
  Fish_Currys120,
  Fish_Currys150,
  Prawns_Currys120,
  Prawns_Currys150,
  Non_Veg_Noodles120,
  Non_Veg_Noodles150,
  Mutton_Biryanis120,
  Mutton_Biryanis150,
  Prawns_Biryanis120,
  Prawns_Biryanis150,
  Fish_Biryanis120,
  Fish_Biryanis150,
  Egg_Curry120,
  Egg_Curry150,
  chicken_curry_jx9pd4120,
  chicken_curry_1_Copy_y4vbgs150,
  Mutton_Currys,
  Mutton_Currys_Mob,
  Rotis,
  Rotis_Mob,
  Tiffins,
  Tiffins_Mob,
  Thalis,
  Thalis_Mob,
  DosaAndPessarettu,
  DosaAndPessarettu_Mob,
  Beverages,
  Beverages_Mob,
  Egg_Biryanis,
  Egg_Biryanis_Mob,
  PannerkajuBiryanis,
  PannerkajuBiryanis_Mob,
} from "./imagesImport";

import {
  Veg_Soups120,
  Veg_Soups150,
  Veg_Starters120,
  Veg_Starters150,
  Veg_Curry120,
  Veg_Curry150,
  Panner_Currys120,
  Panner_Currys150,
  Veg_Noodles120,
  Veg_Noodles150,
  Veg_Biryanis120,
  Veg_Biryanis150,
  Salads120,
  Salads150,
  Veg_Rice_Items120,
  Veg_Rice_Items150,
  Non_Veg_Rice_Items120,
  Non_Veg_Rice_Items150,
  dal_items_b9yztq120,
  dal_items_wxa81f150,
} from "./vegImagesImport";

import "./styles.scss";
import CarouselBanner from "./CarouselBanner";
import { Link } from "react-router-dom";
import Testimonials from "./components/Testimonials";
const bawarchiItems = [
  {
    name: "Beverages",
    category: "beverages",
    subcategory: "non-veg",
    image120: Beverages_Mob,
    image150: Beverages,
  },
  {
    name: "Veg Soups",
    category: "starters",
    subcategory: "veg",
    image120: Veg_Soups120,
    image150: Veg_Soups150,
  },
  {
    name: "Idly Wada Puri",
    category: "starters",
    subcategory: "veg",
    image120: Tiffins_Mob,
    image150: Tiffins,
  },
  {
    name: "Veg Starters",
    category: "starters",
    subcategory: "veg",
    image120: Veg_Starters120,
    image150: Veg_Starters150,
  },
  {
    name: "Veg Currys",
    category: "main-course",
    subcategory: "veg",
    image120: Veg_Curry120,
    image150: Veg_Curry150,
  },
  {
    name: "Panner Currys",
    category: "main-course",
    subcategory: "veg",
    image120: Panner_Currys120,
    image150: Panner_Currys150,
  },
  {
    name: "Veg Noodles",
    category: "main-course",
    subcategory: "veg",
    image120: Veg_Noodles120,
    image150: Veg_Noodles150,
  },
  {
    name: "Veg Biryanis",
    category: "main-course",
    subcategory: "veg",
    image120: Veg_Biryanis120,
    image150: Veg_Biryanis150,
  },
  {
    name: "Rotis",
    category: "starters",
    subcategory: "veg",
    image120: Rotis_Mob,
    image150: Rotis,
  },
  {
    name: "SaladsRaitha And Papads",
    category: "starters",
    subcategory: "veg",
    image120: Salads120,
    image150: Salads150,
  },
  {
    name: "Dal Items",
    category: "main-course",
    subcategory: "veg",
    image120: dal_items_b9yztq120,
    image150: dal_items_wxa81f150,
  },
  {
    name: "Veg RiceItems",
    category: "main-course",
    subcategory: "veg",
    image120: Veg_Rice_Items120,
    image150: Veg_Rice_Items150,
  },
  {
    name: "Thalis",
    category: "main-course",
    subcategory: "veg",
    image120: Thalis_Mob,
    image150: Thalis,
  },

  {
    name: "Non Veg Soups",
    category: "starters",
    subcategory: "non-veg",

    image120: Non_Veg_Soups120,
    image150: Non_Veg_Soups150,
  },
  {
    name: "Chicken Starters",
    category: "starters",
    subcategory: "non-veg",
    image120: Chicken_Starters120,
    image150: Chicken_Starters150,
  },
  {
    name: "Mutton Starters",
    category: "starters",
    subcategory: "non-veg",
    image120: Mutton_Starters120,
    image150: Mutton_Starters150,
  },
  {
    name: "Prawns Starters",
    category: "starters",
    subcategory: "non-veg",
    image120: Prawns_Starters1200,
    image150: Prawns_Starters1500,
  },
  {
    name: "Fish Starters",
    category: "starters",
    subcategory: "non-veg",
    image120: Fish_Starters120,
    image150: Fish_Starters150,
  },
  {
    name: "Egg Items",
    category: "starters",
    subcategory: "non-veg",
    image120: Egg_Items120,
    image150: Egg_Items150,
  },
  {
    name: "Kababs And Grills",
    category: "starters",
    subcategory: "non-veg",
    image120: Kababs_Grills120,
    image150: Kababs_Grills150,
  },

  {
    name: "Egg Currys",
    category: "main-course",
    subcategory: "non-veg",
    image120: Egg_Curry120,
    image150: Egg_Curry150,
  },
  {
    name: "Fries",
    category: "starters",
    subcategory: "non-veg",
    image120: Spl_Fish_Tikka120,
    image150: Spl_Fish_Tikka150,
  },
  {
    name: "Chicken Currys",
    category: "main-course",

    subcategory: "non-veg",
    image120: chicken_curry_jx9pd4120,
    image150: chicken_curry_1_Copy_y4vbgs150,
  },
  {
    name: "Fish Currys",
    category: "main-course",

    subcategory: "non-veg",
    image120: Fish_Currys120,
    image150: Fish_Currys150,
  },
  {
    name: "Prawns Currys",
    category: "main-course",

    subcategory: "non-veg",
    image120: Prawns_Currys120,
    image150: Prawns_Currys150,
  },
  {
    name: "Mutton Currys",
    category: "main-course",

    subcategory: "non-veg",
    image120: Mutton_Currys_Mob,
    image150: Mutton_Currys,
  },
  {
    name: "Non Veg Noodles",
    category: "main-course",
    subcategory: "non-veg",
    image120: Non_Veg_Noodles120,
    image150: Non_Veg_Noodles150,
  },
  {
    name: "Chicken Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120:
      "https://b.zmtcdn.com/data/dish_images/d19a31d42d5913ff129cafd7cec772f81639737697.png?fit=around|120:120&crop=120:120;*,*",
    image150:
      "https://b.zmtcdn.com/data/dish_images/d19a31d42d5913ff129cafd7cec772f81639737697.png",
  },
  {
    name: "Mutton Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120: Mutton_Biryanis120,
    image150: Mutton_Biryanis150,
  },
  {
    name: "Egg Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120: Egg_Biryanis_Mob,
    image150: Egg_Biryanis,
  },
  {
    name: "Panner Kaju Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120: PannerkajuBiryanis_Mob,
    image150: PannerkajuBiryanis,
  },
  {
    name: "Prawns Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120: Prawns_Biryanis120,
    image150: Prawns_Biryanis150,
  },
  {
    name: "Fish Biryanis",
    category: "main-course",

    subcategory: "non-veg",
    image120: Fish_Biryanis120,
    image150: Fish_Biryanis150,
  },
  {
    name: "Dosa And Pesarettu",
    category: "starters",

    subcategory: "veg",
    image120: DosaAndPessarettu_Mob,
    image150: DosaAndPessarettu,
  },
  {
    name: "Non Veg RiceItems",
    category: "main-course",

    subcategory: "non-veg",
    image120: Non_Veg_Rice_Items120,
    image150: Non_Veg_Rice_Items150,
  },
];
const Home = ({ smallScreen }) => {
  let images = [];

  let w = window.innerWidth;
  w >= 540
    ? (images = [Desk1, Desk3, Desk2])
    : (images = [Banner1, Banner2, Banner3]);

  const [selector, setSelector] = useState(
    localStorage.getItem("selector") ? localStorage.getItem("selector") : "all"
  );
  const [subSelector, setSubSelector] = useState(
    localStorage.getItem("subSelector")
      ? selector === "beverages" || selector === "desserts"
        ? "non-veg"
        : localStorage.getItem("subSelector")
      : "non-veg"
  );
  const initial = bawarchiItems.filter((item) =>
    selector === "all"
      ? item.subcategory === subSelector
      : item.category === selector && item.subcategory === subSelector
  );

  const [filteredItems, setFilteredItems] = useState(initial);

  return (
    <section>
      <div className="home-img md:mb-0 mb-2">
        <div className="md:h-[85vh] h-[74vh] flex  flex-col items-center justify-center px-2">
          <motion.h1
            className="md:text-6xl text-4xl text-center font-bold  md:text-white"
            initial={{ y: 100 }}
            whileInView={{ y: 0 }}
            transition={{ type: "just" }}
          >
            Hi, Welcome To{" "}
            <span className="text-[#0A0322] md:text-white">
              The Classic Restaurant🫡
            </span>
          </motion.h1>
          <motion.h1
            className="text-[#0A0322] md:text-white lg:px-44 px-4 text-lg font-bold text-center py-5"
            initial={{ y: 100 }}
            whileInView={{ y: 0 }}
            transition={{ type: "tween", stiffness: 100, delay: 0.19 }}
          >
            Step into a world where each dish is a masterpiece, meticulously
            crafted to tantalize your taste buds.
          </motion.h1>
        </div>
      </div>
      <div className={` w-full mx-auto  md:rounded md:px-4 md:py-3`}>
        <CarouselBanner autoSlide={true}>
          {images.map((s) => {
            return (
              <img
                src={s}
                className="md:rounded"
                style={{
                  backgroundPosition: "center",

                  backgroundSize: "cover",
                  width: "100%",
                }}
                alt="carousel-img"
              />
            );
          })}
        </CarouselBanner>
      </div>
      <div className="md:px-10 ">
        <div className="flex md:flex-row flex-col items-center justify-between mt-3 md:px-0 px-5">
          <h2 className="h2 my-4 text-xl font-extrabold">Delicious Menu</h2>

          <div className="flex md:flex-row md:space-y-0 space-y-4 flex-col items-center justify-between space-x-3 my-4">
            <div className="flex  items-center justify-center bg-red-400 px-2 py-1 rounded-lg">
              <button
                className={`${
                  selector === "all"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md"
                    : "bg-red-400 text-gray-700"
                } font-bold px-4 py-1.5  rounded-lg md:text-sm text-[10px]`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) => item.subcategory === subSelector
                  );
                  setFilteredItems(items);
                  setSelector("all");
                  localStorage.setItem("selector", "all");
                }}
              >
                All
              </button>
              <button
                className={`${
                  selector === "starters"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md"
                    : "bg-red-400 text-gray-700"
                } font-bold px-4 py-1.5  rounded-lg md:text-sm text-[10px]`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.category === "starters" &&
                      item.subcategory === subSelector
                  );
                  setFilteredItems(items);
                  setSelector("starters");
                  localStorage.setItem("selector", "starters");
                }}
              >
                Starters
              </button>
              <button
                className={`${
                  selector === "main-course"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md"
                    : "bg-red-400 text-gray-700"
                } font-bold px-4 py-1.5  rounded-lg md:text-sm text-[10px]`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.category === "main-course" &&
                      item.subcategory === subSelector
                  );
                  setFilteredItems(items);
                  setSelector("main-course");
                  localStorage.setItem("selector", "main-course");
                }}
              >
                Main Course
              </button>
              <button
                className={`${
                  selector === "beverages"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md"
                    : "bg-red-400 text-gray-700"
                } font-bold px-4 py-1.5  rounded-lg md:text-sm text-[10px]`}
                onClick={() => {
                  const items = bawarchiItems.filter(
                    (item) =>
                      item.category === "beverages" &&
                      item.subcategory === "non-veg"
                  );
                  setFilteredItems(items);
                  setSelector("beverages");

                  localStorage.setItem("selector", "beverages");
                }}
              >
                Beverages
              </button>
            </div>
            <div className="flex  items-center justify-center bg-red-400 px-2 py-1 rounded-lg">
              <button
                disabled={selector === "beverages" || selector === "desserts"}
                className={`${
                  subSelector === "veg" &&
                  selector !== "beverages" &&
                  selector !== "desserts"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md "
                    : "bg-red-400 text-gray-700"
                } font-bold  px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter((item) =>
                    selector === "all"
                      ? item.subcategory === "veg"
                      : item.subcategory === "veg" && item.category === selector
                  );
                  setFilteredItems(items);
                  setSubSelector("veg");
                  localStorage.setItem("subSelector", "veg");
                }}
              >
                Veg
              </button>
              <button
                disabled={selector === "beverages" || selector === "desserts"}
                className={`${
                  subSelector === "non-veg" &&
                  selector !== "beverages" &&
                  selector !== "desserts"
                    ? "bg-gradient-to-r from-[#617626] to-cyan-600  text-gray-50 shadow-md"
                    : "bg-red-400 text-gray-700"
                } font-bold  px-4 py-1.5 rounded-lg md:text-sm text-xs`}
                onClick={() => {
                  const items = bawarchiItems.filter((item) =>
                    selector === "all"
                      ? item.subcategory === "non-veg"
                      : item.subcategory === "non-veg" &&
                        item.category === selector
                  );
                  setFilteredItems(items);
                  setSubSelector("non-veg");
                  localStorage.setItem("subSelector", "non-veg");
                }}
              >
                Non Veg
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" md:p-8 p-4 rounded-lg">
        <motion.div
          className=" grid grid-cols-3 gap-0 md:grid-cols-5 lg:grid-cols-7 max-w-7xl mx-auto"
          initial={{ y: 100 }}
          whileInView={{ y: 0 }}
          transition={{ type: "keyframes", stiffness: 100 }}
        >
          {filteredItems?.map((item, i) => {
            let joined = item.name.replace(/\s/g, "");

            return (
              <Link to={`/category/${joined}`}>
                <div className="my-4 mx-4 flex flex-col items-center justify-center">
                  <img
                    src={smallScreen ? item.image120 : item.image150}
                    alt="image_i"
                  />
                  <p className="w-[6.6rem] text-center t2uncate my-2 md:text-sm text-xs font-semibold">
                    {item.name}
                  </p>
                </div>
              </Link>
            );
          })}
        </motion.div>
      </div>

      <Testimonials />

      <footer className="bg-[#0A0322]  shadow  ">
        <div className="w-full  mx-auto p-4 md:py-8 space-y-2 ">
          <div className="flex flex-col items-center justify-center">
            <a
              href="/"
              className="flex items-center  sm:mb-0  rtl:space-x-reverse "
            >
              <img
                src="https://res.cloudinary.com/dqupmzcrb/image/upload/e_auto_contrast,q_100/v1685348916/EASY_TYM-removebg-preview_sab2ie.png"
                className="h-10"
                alt="Easytym Logo"
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                Easytym
              </span>
            </a>
          </div>

          <span className="block text-sm text-white text-center font-semibold">
            Do You Also Need a Website/App? Please click on phone number to
            contact us <a href="tel:+918919788492">+91-8919785676</a>.
          </span>

          <span className="block text-sm text-white text-center font-extralight">
            © 2024{" "}
            <a href="https://flowbite.com/" className="hover:underline">
              Easytym™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </section>
  );
};
export default memo(Home);
